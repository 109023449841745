var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contents errorpage" }, [
    _c("div", [
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _c(
        "button",
        { staticClass: "point" },
        [_c("router-link", { attrs: { to: "/" } }, [_vm._v(" 홈으로 이동 ")])],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("i", { staticClass: "fas fa-exclamation-triangle" }),
      _vm._v("Error 404")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 현재 찾으시는 페이지는"),
      _c("br"),
      _vm._v(" 접근이 제한되어 있거나 없는 페이지 입니다. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 홈으로 이동하거나 이전 페이지로 이동 후 "),
      _c("br"),
      _vm._v("다른 페이지를 클릭해주세요. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }